import * as React from 'react';
import { MDFLogin } from '../components';
import { DEFAULTUSERINFO } from '../constants/variables';

export interface Props {
	children?: React.ReactNode;
	[name: string]: any;
}
/**
 *
 */
class Login extends React.PureComponent<Props> {
	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	public render() {
		const { children } = this.props;
		return <MDFLogin userParameters={DEFAULTUSERINFO}>{children}</MDFLogin>;
	}
}

export default Login;
